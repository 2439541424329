.clickable-image-wrapper {
    color: inherit;
    width: 100%;
}

.clickable-image {
    border-radius: 8px;
    color: inherit;
}

.clickable-image.clickable {
    cursor: zoom-in;
}

// .clickable-image:hover {
//     box-shadow: 0 0.5rem 1rem rgba(27, 121, 214, 0.15) !important;
// }

.clickable-image.bad {
    box-shadow: 0 0 0.35rem rgba(214, 27, 27, 1) !important;
}

.clickable-image.good {
    box-shadow: 0 0 0.35rem rgba(27, 214, 58, 1) !important;
}

.clickable-image-modal-header {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border-bottom: none;
}

.clickable-image-modal-body {
    padding: 0;
}

.clickable-image-modal-body > img {
    width: 100%;
}
