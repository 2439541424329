@import "../../../assets/styles/_colors.scss";
@import "../../../assets/styles/_sizes.scss";

.project-selector,
.subject-selector,
.course-selector {
  position: fixed;
  top: 5px;
  left: 50%;
  transform: translateX(-47%);
  z-index: 1031; // Needs to be more than 1030 (Bootstrap sets the z-index of the fixed navbar to 1030)
  background-color: $background-color;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  padding: 4px 0;
  width: fit-content;
}

.project-selector,
.course-selector {
  color: $theme-primary-color-faint;
}

.subject-selector {
  width: 380px;
  max-width: 90%;
}

.select-subject-input {
  color: $theme-secondary-color;
  height: fit-content;
  padding: 10px 18px 14px;
}

.select-subject-input > label {
  font-size: 17px;
  font-weight: 600;
}

.select-project-btn,
.select-course-btn,
.add-project-btn-nav,
.add-course-btn-nav {
  text-align: left;
  width: 100%;
  height: 40px;
  padding: 8px 14px;
  font-weight: 600;
  cursor: pointer;
}

.select-project-btn:hover,
.select-course-btn:hover,
.add-project-btn-nav:hover,
.add-course-btn-nav:hover {
  background-color: $background-color-dark;
}

.select-project-btn.active,
.select-course-btn.active {
  color: $theme-primary-color;
  font-weight: 600;
  text-decoration: underline;
}
