@import "../../../assets/styles/_colors.scss";
@import "../../../assets/styles/_sizes.scss";

#how-it-works-container {
    background-color: $theme-primary-color;
    color: $background-color;
    margin-top: 45px;
    padding-top: 65px;
    padding-bottom: 110px;
}

.how-it-works-section {
    margin-bottom: 55px;
}

.how-it-works-title {
    font-size: 28px;
    color: $background-color;
}

.how-it-works-directions-text {
    font-size: 20px;
}

.step-header {
    font-size: 28px;
}

.step-description {
    margin-top: 8px;
    font-size: 22px;
}

@media (min-width: 576px) {
    #how-it-works-container {
        margin-top: 65px;
    }
    .how-it-works-section {
        margin-bottom: 75px;
    }

    .how-it-works-title {
        font-size: 36px;
    }

    .how-it-works-directions-text {
        font-size: 24px;
    }

    .step-header {
        font-size: 36px;
    }
    .how-it-works-icon,
    .how-it-works-arrow {
        margin-top: 60px;
    }
}

@media (min-width: 768px) {
    #how-it-works-container {
        margin-top: 75px;
    }
    .how-it-works-section {
        margin-bottom: 85px;
    }

    .how-it-works-title {
        font-size: 48px;
    }

    .how-it-works-directions-text {
        font-size: 28px;
    }

    .step-header {
        font-size: 48px;
    }
    .how-it-works-icon,
    .how-it-works-arrow {
        margin-top: 0px;
    }
}

@media (min-width: 992px) {
    #how-it-works-container {
        margin-top: 105px;
    }
    .how-it-works-section {
        margin-bottom: 115px;
    }

    .how-it-works-title {
        font-size: 72px;
    }

    .how-it-works-directions-text {
        font-size: 30px;
    }

    .step-header {
        font-size: 72px;
    }
}
