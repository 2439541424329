@import "../../assets/styles/_colors.scss";

.sending-message-container > div {
  width: 16px;
  height: 16px;
  font-size: 10px;
  margin-right: 10px;
}

.sending-message-container > .text {
  font-size: 16px;
}

.send-feedback-btn {
  padding: 8px 15px;
  border-radius: 4px;
  width: fit-content;
  display: inline-block;
  cursor: pointer;
}

.send-feedback-btn:hover {
  background-color: $background-color;
  color: $theme-secondary-color;
}

.send-feedback-btn:disabled {
  padding: 8px 31px;
  cursor: not-allowed;
  background-color: $theme-secondary-color-light;
  border-color: $theme-secondary-color-light;
  color: $background-color;
}
