@import "./assets/styles/_colors.scss";
@import "./assets/styles/_sizes.scss";

.App {
  padding-top: $navbar-height;
  color: $theme-primary-color;
}

.border-bottom-light {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12); //$theme-primary-color-faint;
}

.inline-link {
  text-decoration: underline;
}

button {
  background-color: unset;
  border: unset;
  padding: unset;
}

// Use this class to make Bootstrap columns equal height
// with their siblings
.equal-height {
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
}

h3,
h4,
h5,
h6,
.h3,
.h4,
.h5,
.h6 {
  color: $theme-primary-color;
}

a:not(:visited) {
  color: #0072ee;
}

.modal-content {
  background-color: $background-color;
}

.modal-header .close-btn {
  font-size: 22px;
  color: $theme-primary-color;
  cursor: pointer;
  margin-left: auto;
}

.modal-header .close-btn:hover {
  color: $theme-primary-color-faint;
}

.min-height-page-container {
  min-height: calc(100vh - #{$navbar-height} - 168px);
}

@media (min-width: 992px) {
  .min-height-page-container {
    min-height: calc(100vh - #{$navbar-height} - 120px);
  }
}

.divider {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  margin: 4px 12px;
}

.cursor-pointer {
  cursor: pointer;
}

// BEGIN CHECKBOX STYLING
.presly-form-check {
  background-color: $theme-secondary-color;
  border-radius: 4px;
  padding: 7px 10px 7px 38px;
}

.presly-form-check ~ .presly-form-check {
  margin-top: 0.5rem;
}

.no-select {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.presly-form-check,
.presly-form-check > * {
  cursor: pointer;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.presly-form-check > input {
  display: none;
}

.presly-form-check > label {
  display: inline-block;
  position: relative;
}

.presly-form-check > label:before {
  color: $background-color;
  background-color: $background-color;
  border: 3px solid $background-color;
  border-radius: 4px;
  width: 17px;
  height: 17px;
  padding-left: 2px;
  font-size: 19px;
  position: absolute;
  top: 5px;
  left: -26px;
  content: " ";
}

.presly-form-check > input:checked + label:before {
  background-color: $theme-primary-color;
}

// .presly-form-check.checklist-item > input:checked + label:before {
.presly-form-check > input:checked + label:before {
  content: "\2714";
  background-color: $theme-secondary-color;
  border: 1px solid $theme-secondary-color;
  top: -1px;
  left: -28px;
}

.presly-link {
  text-decoration: underline;
  color: inherit;
}

.presly-link:hover {
  color: inherit;
  text-decoration: none;
}

.presly-input-error {
  color: $failure-color;
  font-size: 0.9rem;
  margin-top: 3px;
  text-align: center;
}

.presly-input {
  color: $theme-secondary-color !important;
  font-weight: 600;
}

.presly-input:not(.sections-select):not(.general-select):not(.subject-select),
.presly-input > div.select__control {
  border: 1px solid $theme-secondary-color !important;
  border-top-color: $theme-secondary-color !important;
  border-bottom-color: $theme-secondary-color !important;
  border-left-color: $theme-secondary-color !important;
  border-right-color: $theme-secondary-color !important;
}

.presly-input.thick,
.presly-input.thick > div.select__control {
  border-width: 2px !important;
}

.presly-input-primary {
  border: 1px solid $theme-primary-color-light !important;
  border-top-color: $theme-primary-color-light !important;
  border-bottom-color: $theme-primary-color-light !important;
  border-left-color: $theme-primary-color-light !important;
  border-right-color: $theme-primary-color-light !important;
  color: $theme-primary-color-light !important;
  font-weight: 600;
}

.presly-input-primary.thick,
.presly-input-primary.thick > div.select__control {
  border-width: 2px !important;
}

.presly-btn {
  border: 1px solid $theme-secondary-color;
  background-color: $theme-secondary-color;
  color: $background-color;
  font-weight: 600;
}

.presly-btn-inverted {
  border: 1px solid $theme-secondary-color;
  background-color: $background-color;
  color: $theme-secondary-color;
  font-weight: 600;
}

.presly-btn-primary {
  border: 1px solid $theme-primary-color;
  background-color: $theme-primary-color;
  color: $background-color;
  font-weight: 600;
}

.presly-btn-primary-inverted {
  border: 1px solid $theme-primary-color;
  background-color: $background-color;
  color: $theme-primary-color;
  font-weight: 600;
}

.presly-btn-primary-light {
  border: 1px solid $theme-primary-color-light;
  background-color: $theme-primary-color-light;
  color: $background-color;
  font-weight: 600;
}

.presly-btn-primary-light-inverted {
  border: 1px solid $theme-primary-color-light;
  background-color: $background-color;
  color: $theme-primary-color-light;
  font-weight: 600;
}

.presly-btn-danger {
  border: 1px solid $bad-color;
  background-color: $bad-color;
  color: $background-color;
  font-weight: 600;
}

.presly-btn-cancel {
  color: $theme-primary-color;
  cursor: pointer;
}

.presly-btn-cancel.light {
  color: $background-color;
}

.presly-btn:hover {
  color: $theme-secondary-color;
  background-color: $background-color;
}

.presly-btn-inverted:hover {
  color: $background-color;
  background-color: $theme-secondary-color;
}

.presly-btn-primary:hover {
  color: $theme-primary-color;
  background-color: $background-color;
}

.presly-btn-primary-inverted:hover {
  color: $background-color;
  background-color: $theme-primary-color;
}

.presly-btn-primary-light:hover {
  color: $theme-primary-color-light;
  background-color: $background-color;
}

.presly-btn-cancel:hover {
  color: $theme-primary-color-faint;
}

.presly-btn-cancel.light:hover {
  color: $background-color-dark;
}

.presly-btn-primary-light-inverted:hover {
  color: $background-color;
  background-color: $theme-primary-color-light;
}

.presly-btn-danger:hover {
  color: $bad-color;
  background-color: $background-color;
}

.presly-btn.thick,
.presly-btn-inverted.thick,
.presly-btn-primary.thick,
.presly-btn-primary-inverted.thick,
.presly-btn-primary-light.thick,
.presly-btn-primary-light-inverted.thick,
.presly-btn-danger.thick,
.no-focus-btn.thick {
  border-width: 2px;
}

.presly-btn.form-control:focus {
  color: $theme-secondary-color;
}

.presly-btn-primary-light.form-control:focus {
  color: $theme-primary-color-light;
}

.presly-btn-primary.form-control:focus {
  color: $theme-primary-color;
}

.presly-input:focus,
.presly-input-primary:focus,
.presly-btn.record-btn:focus,
.react-datepicker-wrapper input:focus,
:not(.badge-form-check):not(.badge-form-check-primary) > label:focus,
.presly-btn-cancel:focus {
  border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
}

.badge-form-check:focus,
.badge-form-check-primary:focus,
select.presly-input:focus,
select.presly-input-primary:focus,
select.presly-input > div.select__control:focus,
select.presly-input-primary > div.select__control:focus,
.presly-btn:not(.record-btn):not(.presly-btn-cancel):focus,
.presly-btn-inverted:focus,
.presly-btn-primary:focus,
.presly-btn-primary-inverted:focus,
.presly-btn-primary-light:focus,
.presly-btn-primary-light-inverted:focus,
.presly-btn-danger:focus,
.no-focus-btn:focus,
span:focus,
button:not(.presly-btn-cancel):not(.clickable-image-wrapper):not(.record-btn):focus,
div:not(.presly-btn-cancel):not(.rc-slider-handle):not(.badge-form-check):focus,
svg:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  outline-offset: 1px;
  outline-style: auto;
  outline-width: 1px;
  outline-color: $theme-primary-color-light;
  border-radius: 0;
}

.presly-btn.record-btn:focus,
:not(.badge-form-check):not(.badge-form-check-primary) > label:focus,
.presly-btn-cancel:focus {
  text-decoration: underline;
}

.badge-form-check:focus {
  outline-color: $theme-secondary-color;
}

.clickable-image-wrapper:focus,
a:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  outline-style: auto;
  outline-width: 1px;
  outline-color: $theme-primary-color-light;
}

.clickable-image-wrapper:focus {
  outline-offset: -1px;
}

a:focus {
  outline-offset: 0px;
}

.presly-btn-inverted:focus {
  border-color: $theme-secondary-color;
}

.presly-btn-primary:focus,
.presly-btn-primary-inverted:focus {
  border-color: $theme-primary-color;
}

.presly-btn-primary-light:focus,
.presly-btn-primary-light-inverted:focus {
  border-color: $theme-primary-color-light;
}

.presly-btn-danger:focus {
  border-color: $bad-color;
}

.no-focus-btn:focus {
  border-color: inherit;
}

// Change the default color of input placeholder text.
.presly-input::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: $theme-secondary-color-light;
}
.presly-input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: $theme-secondary-color-light;
  opacity: 1;
}
.presly-input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: $theme-secondary-color-light;
  opacity: 1;
}
.presly-input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: $theme-secondary-color-light;
}
.presly-input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: $theme-secondary-color-light;
}

.presly-input::placeholder {
  /* Most modern browsers support this now. */
  color: $theme-secondary-color-light;
}

// Change the color of input placeholder text for .presly-input-primary elements.
.presly-input-primary::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: $theme-primary-color-light-faint;
}
.presly-input-primary:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: $theme-primary-color-light-faint;
  opacity: 1;
}
.presly-input-primary::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: $theme-primary-color-light-faint;
  opacity: 1;
}
.presly-input-primary:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: $theme-primary-color-light-faint;
}
.presly-input-primary::-ms-input-placeholder {
  /* Microsoft Edge */
  color: $theme-primary-color-light-faint;
}
.presly-input-primary::placeholder {
  /* Most modern browsers support this now. */
  color: $theme-primary-color-light-faint;
}

a.clear-link-styling {
  display: block;
  color: inherit; /* blue colors for links too */
  text-decoration: inherit; /* no underline */
}

// START overrides for react-select styling
.presly-input div.select__control {
  min-height: 42px;
  box-shadow: none;
}

.sections-select div.select__control {
  cursor: text;
}

.presly-input .select__clear-indicator,
.presly-input .select__multi-value__remove {
  cursor: pointer;
}
// END overrides for react-select styling

// START overrides for react-toastify styling
.Toastify__toast {
  font-family: inherit;
  padding-left: 24px;
  padding-right: 18px;
  font-weight: 600;
}
.Toastify__toast-body {
  padding-right: 18px;
}
.Toastify__toast--error {
  background-color: $failure-color;
}

@media (min-width: 480px) {
  .Toastify__toast-container {
    max-width: calc(100% - 30px);
    width: fit-content;
  }

  .Toastify__toast {
    border-radius: 4px;
  }
}
@media (min-width: 768px) {
  .Toastify__toast-container {
    max-width: 80%;
  }
}
@media (min-width: 992px) {
  .Toastify__toast-container {
    max-width: 70%;
  }
}
@media (min-width: 1200px) {
  .Toastify__toast-container {
    max-width: 60%;
  }
}
// END overrides for react-toastify styling
