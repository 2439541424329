$theme-primary-color: #07889b;
// $theme-primary-color: #008295;  // High-contrast version
$theme-primary-color-faint: #4ba9b6;
$theme-primary-color-faint-50: #8dd2db;
$theme-primary-color-faint-25: #bceef5;
$theme-primary-color-light: #66b98f;
// $theme-primary-color-light: #008a3c;  // High-contrast version
$theme-primary-color-light-faint: #9dd6b9;
$theme-primary-color-light-faint-50: #b8e0cb;
$theme-primary-color-light-faint-25: #d4ece0;
$theme-secondary-color: #e37222;
// $theme-secondary-color: #bd5a00;  // High-contrast version
$theme-secondary-color-light: #eeaa7b;
// $theme-secondary-color-light: #d6801d;  // High-contrast version
$theme-secondary-color-lighter: #f5bc94;
// $background-color: #f8fafa;
$background-color: #fff;  // High-contrast version
$background-color-alt: #f5f5f5;
$background-color-dark: #e1e4ec;
$theme-secondary-color-0: $background-color;
$theme-secondary-color-5: #fcf1e9;
$theme-secondary-color-10: #f9e3d3;
$theme-secondary-color-20: #f7d5bd;
$theme-secondary-color-30: #f4c7a7;
$theme-secondary-color-40: #f1b991;
$theme-secondary-color-50: #eeaa7a;
$theme-secondary-color-60: #eb9c64;
$theme-secondary-color-70: #e98e4e;
$theme-secondary-color-80: #e68038;
$theme-secondary-color-90: #e37222;
$failure-color: #bf1d2a;
$success-color: #2abf1d;
$bad-color: #e55934;
// $bad-color: #d54400;  // High-contrast version

:export {
    themePrimaryColor: $theme-primary-color;
    themePrimaryColorFaint: $theme-primary-color-faint;
    themePrimaryColorFaint50: $theme-primary-color-faint-50;
    themePrimaryColorFaint25: $theme-primary-color-faint-25;
    themePrimaryColorLight: $theme-primary-color-light;
    themePrimaryColorLightFaint: $theme-primary-color-light-faint;
    themePrimaryColorLightFaint50: $theme-primary-color-light-faint-50;
    themePrimaryColorLightFaint25: $theme-primary-color-light-faint-25;
    // themePrimaryColorDark: $theme-primary-color-dark;
    themeSecondaryColor: $theme-secondary-color;
    themeSecondaryColorLight: $theme-secondary-color-light;
    themeSecondaryColorLighter: $theme-secondary-color-lighter;
    themeSecondaryColor0: $theme-secondary-color-0;
    themeSecondaryColor5: $theme-secondary-color-5;
    themeSecondaryColor10: $theme-secondary-color-10;
    themeSecondaryColor20: $theme-secondary-color-20;
    themeSecondaryColor30: $theme-secondary-color-30;
    themeSecondaryColor40: $theme-secondary-color-40;
    themeSecondaryColor50: $theme-secondary-color-50;
    themeSecondaryColor60: $theme-secondary-color-60;
    themeSecondaryColor70: $theme-secondary-color-70;
    themeSecondaryColor80: $theme-secondary-color-80;
    themeSecondaryColor90: $theme-secondary-color-90;
    failureColor: $failure-color;
    successColor: $success-color;
    backgroundColor: $background-color;
    backgroundColorAlt: $background-color-alt;
    backgroundColorDark: $background-color-dark;
    badColor: $bad-color;
}
