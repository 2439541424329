@import "../../assets/styles/_colors.scss";
@import "../../assets/styles/_sizes.scss";

#presly-navbar {
  padding-left: 4%;
  padding-right: 4%;
}

#presly-navbar.navbar-light {
  background-color: $background-color;
  color: $theme-primary-color;
}
#presly-navbar.navbar-dark {
  background-color: $theme-primary-color;
  color: $background-color;
}

#navbar-logo {
  vertical-align: top;
  margin-top: -2px;
  height: 28px;
}

#navbar-title {
  line-height: 30px;
  font-size: 30px;
  font-family: "Kalam";
}

.course-menu,
.subject-menu,
.project-menu {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  font-weight: 600;
  cursor: pointer;
}

.course-menu,
.project-menu {
  display: none;
}

#navbar-links {
  flex-direction: row;
}

#presly-navbar.navbar-dark #navbar-logo {
  filter: brightness(0) invert(1); // Make the image white
}

.navbar-light #navbar-title {
  color: $theme-primary-color;
}

#user-name-container {
  position: relative;
  display: inline-block;
}

#user-name-container > div {
  font-size: 16px;
  font-weight: 600;
  position: absolute;
  top: -21px;
  right: 8px;
  white-space: nowrap;
}

#user-icon-container {
  padding-top: 7px;
  padding-bottom: 4px;
  cursor: pointer;
}

#user-icon-container > svg {
  width: 24px;
  height: 24px;
}

#navbar-use-without-account-btn > a,
#navbar-demo-btn > a {
  color: inherit;
  line-height: 16px;
  font-weight: 600;
  margin-right: 10px;
}

#navbar-sign-in-btn {
  border-radius: 3px;
  padding: 6px 14px;
  font-weight: 600;
}

.navbar-light #navbar-sign-in-btn {
  border: 1px solid $theme-primary-color;
  color: $background-color;
  background-color: $theme-primary-color;
}

.navbar-light #navbar-sign-in-btn:hover {
  background-color: $background-color;
  color: $theme-primary-color;
}

.navbar-dark #navbar-sign-in-btn {
  border: 1px solid $background-color;
  background-color: $background-color;
  color: $theme-primary-color;
}

.navbar-dark #navbar-sign-in-btn:hover {
  background-color: $theme-primary-color;
  color: $background-color;
}

////// Begin Compact Styling //////

#presly-navbar.compact {
  padding-top: 0;
  padding-bottom: 0;
  min-height: unset;
}

#presly-navbar.compact #navbar-logo {
  height: 28px;
  margin-top: 1px;
}

#presly-navbar.compact #navbar-title {
  line-height: 22px;
  font-size: 22px;
  font-family: "Kalam";
}

#presly-navbar.compact #navbar-use-without-account-btn > a,
#presly-navbar.compact #navbar-demo-btn > a {
  line-height: 14px;
  font-size: 15px;
}

#presly-navbar.compact #navbar-sign-in-btn {
  padding: 3px 12px;
  font-weight: 600;
  font-size: 15px;
}

////// End Compact Styling //////

////// Begin Responsive Styling //////
.verbose-navbar-links {
  font-size: 13px;
}

@media (min-width: 420px) {
  .verbose-navbar-links {
    font-size: inherit;
  }

  #navbar-use-without-account-btn > a,
  #navbar-demo-btn > a {
    line-height: 26px;
    margin-right: 1rem;
  }

  #navbar-sign-in-btn {
    padding: 8px 25px;
  }
}

@media (min-width: 576px) {
  #presly-navbar {
    padding-left: 0;
    padding-right: 0;
  }

  .course-menu,
  .subject-menu,
  .project-menu {
    display: block;
  }

  #navbar-logo {
    margin-top: -2px;
    height: 32px;
  }

  #navbar-title {
    line-height: 34px;
    font-size: 34px;
  }
}

@media (min-width: 768px) {
  #navbar-logo {
    margin-top: -5px;
    height: 36px;
  }

  #navbar-title {
    line-height: 32px;
    font-size: 40px;
  }
}

@media (min-width: 992px) {
  #presly-navbar {
    min-height: $navbar-height;
  }

  #navbar-logo {
    margin-top: -7px;
    height: 46px;
  }

  #navbar-title {
    line-height: 40px;
    font-size: 50px;
  }
}

////// End Responsive Styling //////
