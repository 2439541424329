@import "../../assets/styles/_colors.scss";

.presly-feedback-modal .modal-content {
  color: $theme-primary-color;
  background-color: $background-color;
  border: none;
}

.presly-feedback-modal-header {
  padding: 1.25rem 2rem;
  font-size: 22px;
  font-weight: 600;
}

.presly-feedback-modal-body {
  padding: 1.5rem 2rem 0 2rem;
}

.presly-feedback-modal-footer {
  border-top: none;
  padding: 0.75rem 2rem;
}

.send-presly-feedback-btn {
  padding: 8px 15px;
  border-radius: 4px;
  width: fit-content;
  display: inline-block;
  cursor: pointer;
}

.send-presly-feedback-btn:hover {
  background-color: $background-color;
  color: $theme-secondary-color;
}

.send-presly-feedback-btn:disabled {
  padding: 8px 31px;
  cursor: not-allowed;
  background-color: $theme-secondary-color-light;
  border-color: $theme-secondary-color-light;
  color: $background-color;
}

.submit-feedback-success-message {
  color: $theme-primary-color-light;
  font-size: 24px;
}

.submit-feedback-thank-you-message {
  margin-bottom: 80px;
}
