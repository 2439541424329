@import "../../assets/styles/_colors.scss";
@import "../../assets/styles/_sizes.scss";

#landing-page-container {
  padding-top: $default-page-container-padding-top + 10px;
}

#intro-text-large {
  font-size: 24px;
  color: $theme-primary-color;
}

.showcase-photo-container {
  position: relative;
}

.showcase-photo-message,
.showcase-photo-feedback-bot {
  width: 100%;
}

.showcase-photo-container > button:first-of-type {
  width: 90%;
}

.showcase-photo-container > button:last-of-type {
  position: absolute;
  z-index: 3;
  top: 18%;
  left: 10%;
  width: 88%;
}

#summary-text-row {
  margin-top: 30px;
  font-size: 20px;
  color: $theme-primary-color;
}

@media (min-width: 576px) {
  #intro-text-large {
    font-size: 30px;
  }

  #summary-text-row {
    margin-top: 35px;
    font-size: 28px;
  }

  .showcase-photo-message,
  .showcase-photo-feedback-bot {
    width: 100%;
  }

  .showcase-photo-container > button:first-of-type {
    width: 70%;
  }

  .showcase-photo-container > button:last-of-type {
    position: absolute;
    z-index: 3;
    top: 35%;
    left: 40%;
    width: 60%;
  }
}

@media (min-width: 768px) {
  #intro-text-large {
    font-size: 36px;
  }

  #summary-text-row {
    margin-top: 45px;
    font-size: 28px;
  }

  .showcase-photo-message,
  .showcase-photo-feedback-bot {
    width: 100%;
  }

  .showcase-photo-container > button:first-of-type {
    width: 65%;
  }

  .showcase-photo-container > button:last-of-type {
    position: absolute;
    z-index: 3;
    top: 35%;
    left: 42%;
    width: 58%;
  }
}

@media (min-width: 992px) {
  #intro-text-large {
    font-size: 42px;
  }

  #summary-text-row {
    margin-top: 65px;
    font-size: 30px;
  }

  .showcase-photo-message,
  .showcase-photo-feedback-bot {
    width: 100%;
  }

  .showcase-photo-container > button:first-of-type {
    width: 60%;
  }

  .showcase-photo-container > button:last-of-type {
    position: absolute;
    z-index: 3;
    top: 35%;
    left: 50%;
    width: 50%;
  }
}

@media (min-width: 1200px) {
  #intro-text-large {
    font-size: 52px;
  }

  #summary-text-row {
    margin-top: 65px;
    font-size: 30px;
  }
}
